import React from 'react'
// import Button from '../components/Common/Button'
import Footer from '../components/Footer/Footer'
import Header from '../components/Header/Header'

function Welcome() {
  return (
    <section className="mt-6">
      <div className="max-w-7xl mx-auto pt-6 px-16 items-center">
        <div className="flex flex-col justify-center items-center">
          <div className="max-w-4xl text-center sm:text-4xl lg:text-6xl text-mirage">
            Customer experiences a great deal of <span className="text-energy-yellow font-semibold">digital evenness</span> while ordering scrumptious food at your restaurant!
          </div>
          <div className="mt-16 text-center lg:text-4xl sm:text-2xl text-mirage font-extralight">
            Let <span className="font-semibold italic"> Contactless dining </span> be the new normal
          </div>
          <div className="max-w-md mt-16 mb-32 flex lg:space-x-8 space-x-4">
            <img src="/assets/icons/file.png" alt="menu" />
            <img src="/assets/icons/bell.png" alt="call" />
            <img src="/assets/icons/food.png" alt="food" />
            <img src="/assets/icons/drink.png" alt="drink" />
            <img src="/assets/icons/tea.png" alt="cafe" />
            <img src="/assets/icons/glass.png" alt="glass" />
          </div>
        </div>
      </div>
    </section>
  )
}

function Restaurants() {
  return (
    <section>
      <div className="relative overflow-hidden">
        <img className="object-cover w-full" src="/assets/images/cafe.png" alt="restaurants" />
        <div className="absolute px-6 py-4 md:top-20 md:right-0 lg:top-56 lg:right-32">
          <div className="absolute lg:top-60 lg:-left-64 px-6 py-4 md:top-24 md:-left-64">
            <img src="/assets/icons/cafe-arrow.png" alt="cafe-arrow" />
          </div>
          <div className="bg-white max-w-xs rounded-3xl px-7 pt-28 pb-7">
            <div className="absolute lg:-top-20 lg:left-12 px-6 py-4 md:-top-20 md:left-12">
              <img src="/assets/images/food-dish.png" alt="food-dish" />
            </div>
            <div className="mt-3 text-2xl font-medium text-nero">Healthy Salad Bowl </div>
            <div className="flex mt-3 space-x-3 items-center">
              <div className="text-nero/40 text-lg font-medium">24min  •</div>
              <div><img src="/assets/icons/star.png" alt="star" /></div>
              <div className="text-nero/40 text-lg font-medium">4.8</div>
            </div>
            <div className="mt-3 flex justify-between items-center">
              <div className="text-nero text-2xl font-bold">
                299/-
              </div>
              <div className="bg-black rounded-2xl px-3 py-3">
                <img src="/assets/icons/cart.png" alt="cart" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

function DigitalMenu() {
  return (
    <section>
      <div className="w-full px-10 mb-10 -mt-1">
        <div className="lg:flex items-center">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-16 items-center">
            <div className="items-center">
              <img src="/assets/images/digital-menus.png" alt="digital-menus" />
            </div>
            <div className="order-first lg:order-last mt-3">
              <div className="text-7xl font-ostrich-sans">
                DIGITAL MENUS
              </div>
              <div className="text-3xl font-extralight text-mirage pt-9">
                Ensure the <span className="font-normal">safety</span> & <span className="font-normal">hygiene</span> of your customers with contactless menu
              </div>
              <div className="pt-9">
                <div className="lg:flex lg:space-x-10 md:flex md:space-x-10 text-xl font-extralight text-mirage items-center">
                  <div className="mb-2">Safer to use</div>
                  <div className="h-2 w-2 bg-mirage rounded-full mb-2"></div>
                  <div className="mb-2">Easy to update</div>
                  <div className="h-2 w-2 bg-mirage rounded-full mb-2 "></div>
                  <div className="mb-2">POS integration</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

function ContentLessDine() {
  return (
    <section className="bg-black">
      <div className="max-w-7xl mx-auto pt-6 items-center">
        <div className="grid grid-flow-row sm:grid-flow-col gap-5 pt-28 pb-20">
          <div className="flex flex-col justify-center px-3">
            <div className="text-white font-ostrich-sans text-7xl font-black">
              contactless Dining
            </div>
            <div className="max-w-xl pt-11 text-white font-light text-3xl">
              Unlock your Contactless Dining with <span className="font-medium">QR Code</span> Menus at your Restaurant
            </div>
            <div className="pt-9 mb-6">
              <div className="lg:flex lg:space-x-10 md:flex md:space-x-10 text-xl font-extralight text-white items-center">
                <div className="mb-2">Scan</div>
                <div className="h-2 w-2 bg-white rounded-full mb-2"></div>
                <div className="mb-2">Browse</div>
                <div className="h-2 w-2 bg-white rounded-full mb-2"></div>
                <div className="mb-2">Order</div>
              </div>
            </div>
          </div>
          <div className="px-3">
            <img src="/assets/images/contactless-dine.png" alt="contactless-dine" />
          </div>
        </div>
      </div>
    </section>
  )
}

function Payment() {
  return (
    <section>
      <div className="w-full px-10">
        <div className="grid lg:grid-cols-2 items-center">
          <img src="/assets/images/payment.png" alt="payment" />
          <div className="flex flex-col justify-center">
            <div className="text-black font-ostrich-sans text-7xl font-black">
              Payment
            </div>
            <div className="max-w-xl pt-11 text-mirage font-extralight text-3xl">
              Waiting for someone to bring or make a bill for you seems tedious at times, but through <span className="font-normal">Jod-e,</span> your payment takes no less than a minute!
            </div>
            <div className="pt-9 mb-6">
              <div className="lg:flex lg:space-x-10 md:flex md:space-x-10 text-xl font-extralight text-mirage items-center">
                <div className="mb-2">View invoice</div>
                <div className="h-2 w-2 bg-mirage rounded-full mb-2"></div>
                <div className="mb-2">Scan</div>
                <div className="h-2 w-2 bg-mirage rounded-full mb-2"></div>
                <div className="mb-2">Pay</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

function SomeMoreFeature() {
  return (
    <section>
      <div className="w-full">
        <div className="relative">
          <img src="/assets/images/more-feature.png" alt="more-feature" className="w-full" />
          <div className="lg:absolute lg:top-1/2 lg:left-1/4 md:absolute md:top-1/2 md:left-1/4">
            <div className="lg:text-white md:text-white text-black font-ostrich-sans text-7xl text-center">some more features</div>
            <div className="lg:flex lg:justify-end hidden">
              <img src="/assets/icons/stars.png" alt="stars" />
            </div>
            <div className="max-w-5xl mt-12 grid lg:grid-cols-2 gap-9">
              <div className="bg-white rounded-2xl px-5 pt-9 pb-14">
                <div className="font-semibold text-2xl mb-4 text-center">
                  Queue management
                </div>
                <div className="flex space-x-10">
                  <img src="/assets/icons/queue-management.png" alt="queue-management" className="inline object-fill" />
                  <div className="text-base">
                    No more hassle for offline table management as well as online reservation
                  </div>
                </div>
              </div>
              <div className="bg-white rounded-2xl px-5 pt-9 pb-14">
                <div className="font-semibold text-2xl mb-4 text-center">
                  Waiter Calling
                </div>
                <div className="flex space-x-10">
                  <img src="/assets/icons/waiter-calling.png" alt="waiter-calling" className="inline object-fill" />
                  <div className="text-base">
                    Whether you need any miscellaneous hospitality from the waiter, all you have to add it in the app while you order
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

function SmartCard() {
  return (
    <section id='smart-cards'>
      <div className="max-w-[91rem] mx-auto px-16 items-center">
        <div className="grid lg:grid-cols-2 items-center">
          <div className="lg:row-span-full lg:col-start-1 lg:col-span-6 lg:self-center">
            <div className="flex flex-col justify-center">
              <div className="text-black font-ostrich-sans text-7xl font-black">
                jod-e smart card
              </div>
              <div className="max-w-xl pt-11 text-black font-extralight text-3xl">
                Jod-e provides a permanent QR code along with a NFC card and tag to instantly share your profile with anyone you meet, online or offline!
              </div>
            </div>
          </div>
          <img src="/assets/images/smart-card.png" alt="smart-card" className="lg:row-span-full lg:col-span-6 lg:col-end-11 lg:self-center lg:pt-56 order-first ls:order-last" />
        </div>
      </div>
    </section>
  )
}

function WhyDigitalMenu() {
  return (
    <section>
      <div className="relative overflow-hidden">
        <img className="object-cover w-full" src="/assets/images/why-digial-menu.png" alt="why-digial-menu" />
        <div className="lg:absolute lg:top-12 lg:left-1/4">
          <div className="lg:text-white md:text-white text-black font-ostrich-sans text-7xl text-center">Why you should choose Digital menus?</div>
        </div>
        <div className="lg:flex lg:absolute lg:top-72 lg:left-[55rem] hidden">
          <img src="/assets/icons/digitalMenuArrow.png" alt="digitalMenuArrow.png" />
        </div>
        <div className="max-w-md grid lg:grid-cols-2 gap-9 lg:absolute lg:top-44 lg:right-80">
          <div className="bg-white rounded-2xl px-5 pt-9 pb-9">
            <div>
              <img src="/assets/icons/creative.png" alt="creative" className="inline object-fill" />
            </div>
            <div className="text-xl font-extralight">
              Digital menus are creative, dynamic and fun
            </div>
          </div>
          <div className="bg-white rounded-2xl px-5 pt-9 pb-9">
            <div>
              <img src="/assets/icons/increase-graph.png" alt="increase-graph" className="inline object-fill" />
            </div>
            <div className="text-xl font-extralight">
              Digital menus can increase Revenue by 20%
            </div>
          </div>
          <div className="bg-white rounded-2xl px-5 pt-9 pb-9">
            <div>
              <img src="/assets/icons/safe.png" alt="safe" className="inline object-fill" />
            </div>
            <div className="text-xl font-extralight">
              Digital menus are safe during the pandemic
            </div>
          </div>
          <div className="bg-white rounded-2xl px-5 pt-9 pb-9">
            <div>
              <img src="/assets/icons/decrease-graph.png" alt="decrease-graph" className="inline object-fill" />
            </div>
            <div className="text-xl font-extralight">
              Digital menus can decrease operational costs by 30%
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

// function ContactUs() {
//   return (
//     <section className="bg-black">
//       <div className="max-w-7xl mx-auto pt-6 px-16 items-center">
//         <div className="flex flex-col justify-center items-center">
//           <div className="text-white font-ostrich-sans text-4xl font-black text-center">
//             Want to know us better?
//           </div>
//           <div className="text-white font-ostrich-sans text-4xl font-black text-center">
//             We are just a message away!
//           </div>
//           <form className="max-w-7xl mt-12 mb-20">
//             <div>
//               <div className="mb-2">
//                 <label className="text-white text-xs">Full Name</label>
//               </div>
//               <div>
//                 <input type="text" className="w-full h-12 rounded-md" />
//               </div>
//             </div>
//             <div className="mt-5">
//               <div className="mb-2">
//                 <label className="text-white text-xs">Email address</label>
//               </div>
//               <div>
//                 <input type="email" className="w-full h-12 rounded-md" />
//               </div>
//             </div>
//             <div className="mt-5">
//               <div className="mb-2">
//                 <label className="text-white text-xs">Write any message</label>
//               </div>
//               <div>
//                 <textarea rows="6" cols="60" className="w-full rounded-md">
//                 </textarea>
//               </div>
//             </div>
//             <div className="mt-3">
//               <Button className="bg-white text-black font-semibold rounded-full px-6 py-2">
//                 Send
//               </Button>
//             </div>
//           </form>
//         </div>
//       </div>
//     </section>
//   )
// }

const LandingPage = () => {
  return (
    <div className="min-h-screen">
      <Header />
      <Welcome />
      <Restaurants />
      <DigitalMenu />
      <ContentLessDine />
      <Payment />
      <SomeMoreFeature />
      <SmartCard />
      <WhyDigitalMenu />
      {/* <ContactUs /> */}
      <Footer />
    </div>
  )
}

export default LandingPage