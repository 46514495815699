import { createBrowserRouter, createRoutesFromElements, Route } from "react-router-dom";
import LandingPage from "./pages/landingPage";

export const router = createBrowserRouter(
    createRoutesFromElements(
        <Route >
            <Route path="/" element={<LandingPage />} />
            {/* <Route element={<Layout />} errorElement={<ErrorPage />}>
            </Route> */}
        </Route>
    )
);