import React, { useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { Transition } from '@headlessui/react';

const Header = () => {

  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef();

  return (
    <nav className="max-w-7xl mx-auto">
      <div className="flex px-11 py-6 justify-between items-center">
        <Link to="/" className='flex items-center space-x-3 select-none'>
          <img src="/logo.png" alt="logo" className="" />
          <div className="text-2xl font-normal">jod-e</div>
        </Link>
        <div className="lg:flex lg:space-x-4 hidden">
          <a href="#smart-cards" className="text-base">Smart cards</a>
          <Link to="https://app.jod-e.com/" className="text-base" target='_blank'>Profile</Link>
        </div>
        <div className="flex lg:hidden">
          <button
            onClick={() => setIsOpen(!isOpen)}
            type="button"
            className="bg-black inline-flex items-center justify-center p-1.5 rounded-md text-gray-400 hover:text-black focus:outline-none"
            aria-controls="mobile-menu"
            aria-expanded="false"
          >
            <span className="sr-only">Open main menu</span>
            {!isOpen ? (
              <svg
                className="block h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="white"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            ) : (
              <svg
                className="block h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="white"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            )}
          </button>
        </div>
      </div>
      <Transition
        show={isOpen}
        enter="transition ease-out duration-100 transform"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="transition ease-in duration-75 transform"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        {() => (
          <div className="lg:hidden" id="mobile-menu">
            <div ref={ref} className="px-9 pt-2 pb-3 sm:px-3">
              <div><a href="#smart-cards" className="text-base">Smart cards</a></div>
              <div><Link to="https://app.jod-e.com/" className="text-base" target='_blank'>Profile</Link></div>
            </div>
          </div>
        )}
      </Transition>
    </nav>
  )
}

export default Header