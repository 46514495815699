import React from 'react'
// import { Link } from 'react-router-dom'
// import Button from '../Common/Button'

const Footer = () => {
    return (
        <footer className="py-4 bg-black text-white text-center sm:text-base text-sm">Copyright &copy; 2023 jod-e. Contact us on <a href="tel:9825069264">+91-9825069264</a> for any inquiry.</footer>
        // <section>
        //     <div className="max-w-7xl mx-auto px-11 pt-16 pb-32">
        //         <div className="text-2xl font-extrabold">
        //             JOD-E
        //         </div>
        //         <div className="lg:flex justify-between">
        //             <div>
        //                 <div className="flex space-x-4 items-center">
        //                     <div>
        //                         <img src="/assets/fb.png" alt="fb" />
        //                     </div>
        //                     <div>
        //                         <img src="/assets/insta.png" alt="instagram" />
        //                     </div>
        //                     <div>
        //                         <img src="/assets/youtube.png" alt="youtube" />
        //                     </div>
        //                     <div>
        //                         <img src="/assets/in.png" alt="linked-in" />
        //                     </div>
        //                 </div>
        //             </div>
        //             <div>
        //                 <div className="grid lg:grid-cols-4 grid-cols-2 lg:gap-5">
        //                     <div>
        //                         <Link to="/" className="text-lg font-normal">About</Link>
        //                     </div>
        //                     <div>
        //                         <Link to="/" className="text-lg font-normal">Features</Link>
        //                     </div>
        //                     <div>
        //                         <Link to="/" className="text-lg font-normal">Contact us</Link>
        //                     </div>
        //                     <div>
        //                         <Link to="/" className="text-lg font-normal">Team</Link>
        //                     </div>
        //                 </div>
        //             </div>
        //             <div className="flex space-x-3">
        //                 <div className="text-2xl font-normal">Let's do it! — </div>
        //                 <div>
        //                     <Button
        //                         type="submit"
        //                         className="text-white text-base bg-black rounded-full px-6 py-2"
        //                     >
        //                         Contact Us
        //                     </Button>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // </section>
    )
}

export default Footer
